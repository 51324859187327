// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React from "react";
import { Container,  Header, Segment, List, Image, Message, Embed } from 'semantic-ui-react';
import QualityHeader from "../../assets/demofactory/quality-header.png";
import GenAITeaserVideo from '../../assets/qap-v2-genai-teaser.mp4';
import GenAITeaserThumbnail from '../../assets/qap-v2-genai-teaser.png';

export default function LandingPage() {
    return (
        <Container>
            <Segment basic>
                <Image src={QualityHeader} />
                <Message positive>
                        Welcome! This is the <b>Quality Assurance</b> environment for the&nbsp;
                        <a href="https://demo-factory.corp.amazon.com/app/browse/" target="_blank">Demo Factory</a>!
                        It is powered by the <a href="https://w.amazon.com/bin/view/BDSI_Solutions_Prototyping/QualityAssurancePortal" target="_blank">
                        Quality Assurance Portal</a>, which is a tool that performs a series of automated quality checks 
                        in source code repositories and report found issues to developers for mitigation. The Quality Portal 
                        leverages tools such as <a href="https://aws.amazon.com/codeguru/" target="_blank">Amazon CodeGuru</a>&nbsp;
                        for coding best practices and security,
                        &nbsp;<a href="https://github.com/stelligent/cfn_nag" target="_blank">cfn-nag</a>&nbsp;
                        for CloudFormation template security analysis,
                        &nbsp;<a href="https://docs.npmjs.com/cli/v9/commands/npm-audit" target="_blank">npm audit</a>&nbsp;
                        for package dependency analysis, and other tools.
                        Our mission is to raise the quality bar for the demos we build for our beloved customers.
                </Message>
            </Segment>

            <Segment basic>
            <Header as='h3'>How it Works</Header>
            <List ordered>
                <List.Item>Contact the <a href="https://demo-factory.corp.amazon.com/app/browse/" target="_blank">Demo Factory</a> team to create a repository for your demo</List.Item>
                <List.Item>Once the repository is created, continue to push code changes to the repository</List.Item>
                <List.Item>Check the <a href="/quality_dashboard">Quality Dashboard</a> to see quality analysis results for your repository</List.Item>
                <List.Item>Use <a href="https://broadcast.amazon.com/videos/814090" target="_blank">Generative AI</a> to fix your code and continue from step 2</List.Item>
                <List.Item>Receive automated code analysis results in your email</List.Item>
                <List.Item>If you don't agree with an analysis result, <a href="https://broadcast.amazon.com/videos/814092" target="_blank">you can request</a> that it be reviewed.</List.Item>
                <List.Item><a href="https://form.asana.com/?k=iTcQ3yA1PkuX6qL3jA3O4g&d=8442528107068">Provide feedback and suggestions</a> to improve the tool</List.Item>
            </List>
            </Segment>
            <Segment basic>
                <Header as='h3'>
                    Highlight: Leverage Amazon Bedrock and GenAI to improve code quality
                </Header>
                <Embed
                    id='qap-genai-teaser-video' // Provide a unique ID for the video
                    placeholder={GenAITeaserThumbnail} // Optional thumbnail image for the video
                    url={GenAITeaserVideo} // Pass the imported video file to the `url` prop
                />
            </Segment>
        </Container>
    );
}