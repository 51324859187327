// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Image, Label } from "semantic-ui-react";
import spdLogo from '../assets/AWS_Industries_Solutions_Prototyping_Black.png'
import "./Footer.css"
import {app_version, app_version_date} from "./version.js"

export class Footer extends Component {

    render() {

        return (
            <div className="footer">
                <Image className='left' src={spdLogo} size='small' />
                <Label className='right' as='a' title={'Deployed on: '+app_version_date} tag>v{app_version}</Label>
            </div>
        )
    }
}
// todo: actual version -> git log -1 --format="%aI"