// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, {Component} from "react";
import { Header, Segment} from 'semantic-ui-react';
import { ThreeDots } from "react-loading-icons";

export class LoginForm extends Component {
    render() {
        return (
            <Segment basic textAlign="center">
                <Header as='h2' color='black' textAlign='center'>
                    Howdy! Let's Raise the Quality Bar?
                </Header>
                <Header as='h4' color='black' textAlign='center'>
                    Signing in through Amazon Federate.
                </Header>
                <ThreeDots stroke="orange"/>
            </Segment>
        );
    }
}


