
const loadModule = (sourcePath, sourceFile) => {
    const componentsPath = './'
    try {
        return require(`${componentsPath}${sourcePath}${sourceFile}`).default;
    }
    catch (err) {
        const defaultPath = './default/';
        return require(`${defaultPath}${sourceFile}`).default;
    }
};

export default loadModule;