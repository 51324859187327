// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, {Component} from 'react';
import {Grid, Header, Segment} from "semantic-ui-react";

export default class Welcome extends Component {

    render() {

        return (
            <Grid padded textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
                <Grid.Row>
                    <Segment basic textAlign='left'>
                        <div align='center'>
                            <Header as='h2'>Welcome to the AWS Quality Assurance Portal!</Header>
                        </div>
                        {/* <Message>
                            <Message.Header>
                                On this website you are able to:
                            </Message.Header>
                            <Message.List>
                                <Message.Item>Generate standard Moment Weight Chart reports in PDF by
                                    uploading raw input files from various HMI vendors
                                </Message.Item>
                                <Message.Item>Inspect reports and adjust header fields as required</Message.Item>
                                <Message.Item>Approve and archive reports</Message.Item>
                                <Message.Item>Search archived reports and re-print them</Message.Item>
                            </Message.List>
                        </Message> */}
                    </Segment>
                </Grid.Row>
            </Grid>
        )
    }
}