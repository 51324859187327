// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

export const colorMap = {
    "critical": "#B2102F",
    "high": "#FF1744",
    "medium": "#FAAD14",
    "low": "#52C41B",
    "unknown": "#7E8890",
    "cfn_review": "#003f5c",
    "code_review": "#a05195",
    "npm_review": "#f95d6a"
    // full pallete: (source: https://learnui.design/tools/data-color-picker.html#divergent)
    // #003f5c
    // #2f4b7c
    // #665191
    // #a05195
    // #d45087
    // #f95d6a
    // #ff7c43
    // #ffa600
}




