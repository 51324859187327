// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { Header, Container, Segment, Table, Divider } from 'semantic-ui-react';
import { Loader } from "./Loader"
import { API } from "aws-amplify";
import QualityFindingsPerCategoryView from "./QualityFindingsPerCategoryView"
import PrototypeDetailsView from "./PrototypeDetailsView";
import ErrorMessageView from "./ErrorMessageView"

export default class LatestQualityFindingsForRepo extends Component {
  constructor(props) {
    super(props);
    this.repositoryName = this.props.match?.params?.repositoryName;
    this.state = {
      loading: true,
      errorFound: false,
      errorMessage: '',
      data: []
    }
  }

  callAPI(apiName, apiPath, apiQueryString) {
    try {
      console.log(`Calling API: ${apiPath}?${apiQueryString}`)
      API.get(
        apiName,
        `${apiPath}?${apiQueryString}`
      ).then(response => {
        console.log(response)
        if (response === []) {
          const errorMessage = `Got an empty response for API call: ${apiPath}?${apiQueryString}`
          console.warn(errorMessage)
          this.setState({
            loading: false,
            errorFound: true,
            errorMessage: errorMessage
          })
        } else {
          this.setState({
            loading: false,
            data: response,
          })
        }
      })
      .catch(error => {
        const errorMessage = `Unexpected error: ${error}`
        console.error(errorMessage);
        this.setState({
          loading: false,
          errorFound: true,
          errorMessage: errorMessage
        })
      })
    } catch (error) {
      const errorMessage = `Unexpected error: ${error}`
      console.error(errorMessage);
      this.setState({
        loading: false,
        errorFound: true,
        errorMessage: errorMessage
      })
    }
  }

  componentDidMount() {
    this.callAPI(
      "PrototypeQualityAPI",
      '/report/latest_quality_findings_for_repo',
      `repository_name=${this.repositoryName}`
    )
  }

  renderFindings() {
    const repoFindings = this.state.data;
    return (
      <Container>
        {
          repoFindings.map((findingsForCategory, index) => {
            return (
              <Segment key={index} basic>
                <Divider />
                <QualityFindingsPerCategoryView
                  key={findingsForCategory.category_id}
                  data={findingsForCategory}
                  userProfile={this.props.userProfile}
                  enableQualityReviews={false}
                />
              </Segment>
            );
          })
        };
      </Container>
    )
  }

  renderSummary() {
    const repoFindings = this.state.data;
    return (
      <Segment basic>
        <Header as="h3">Quality Analysis Summary</Header>
        <Table celled>
          <Table.Body>
            {
              repoFindings.map((findingsForCategory, index) => {
                const { category, total_number_of_findings, review_duration_in_secs } = findingsForCategory;
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{category}</Table.Cell>
                    <Table.Cell>{total_number_of_findings} findings ({review_duration_in_secs} secs)</Table.Cell>
                  </Table.Row>
                );
              })
            }
          </Table.Body>
        </Table>
      </Segment>
    )
  }

  renderHeader() {
    return (
      <Container>
        <Header as="h1" color='black' textAlign='left'>
          Full Quality Findings Report
        </Header>
        <p>
          Generated on: {new Date().toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" })}
        </p>
      </Container>
    )
  }

  render() {
    const data = this.state.data[0];
    return (
      <Container>
        {
          this.state.loading &&
          <Loader message='“Quality means doing it right when no one is looking.” ~Henry Ford' />
        }
        {
          !this.state.loading && 
          !this.state.errorFound && 
          this.renderHeader()
        }
        {
          !this.state.loading && 
          !this.state.errorFound && 
          <PrototypeDetailsView data={data} />
        }
        {
          !this.state.loading && 
          !this.state.errorFound && 
          this.renderSummary()
        }
        {
          !this.state.loading && 
          !this.state.errorFound && 
          this.renderFindings()
        }
        {
          this.state.errorFound &&
          <ErrorMessageView errorMessage={this.state.errorMessage} />
        }
      </Container>
    );
  }
}