// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App.jsx";
import * as serviceWorker from './serviceWorker';
import "./index.css";
import Amplify from "aws-amplify";
import config from "./config";
import Auth from '@aws-amplify/auth';

const oauth = {
  domain: config.cognito.OAUTH.APP_CLIENT_DOMAIN,
  scope: config.cognito.OAUTH.SCOPE,
  redirectSignIn: config.cognito.OAUTH.REDIRECT_SIGN_IN,
  redirectSignOut: config.cognito.OAUTH.REDIRECT_SIGN_OUT,
  responseType: config.cognito.OAUTH.RESPONSE_TYPE
}

Amplify.configure({
  Auth: {
    region: config.cognito.REGION,
    identityPoolRegion: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: oauth
  },
  API: {
    endpoints: [
      {
        name: config.apiGateway.API_NAME,
        endpoint: config.apiGateway.API_URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { 
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` 
          }
        }
      },
    ]
  }
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
