// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from "react";
import { Header, Segment, Table } from 'semantic-ui-react';

export default class PrototypeDetailsView extends Component {

  formatKeyValue(key, keyValue) {
    if (Array.isArray(keyValue))
      return keyValue.join(", ");
    return keyValue;
  }

  extractFieldDescription(fieldName) {
    const words = fieldName.split("_")
    let fieldFormatted = "";
    for (const word in words) {
      let capitalized = words[word].charAt(0).toUpperCase() + words[word].slice(1);
      fieldFormatted += capitalized + " ";
    }
    return fieldFormatted
  }

  render() {
    const detailKeys = ["code_repository_name", "customer_name", "prototype_name", "repo_owner_emails", "tech_lead_login", "tpm_login", "code_repository_creation_date"];
    const { branch_name, commit_id, prototype_details, ignored_file_paths } = this.props.data;
    return (
      <Segment basic>
        <Header as="h3">Repository Details</Header>
        <Table celled>
          <Table.Body>
            {detailKeys.map((key, index) => {
              const fieldDescription = this.extractFieldDescription(key);
              if (Object.keys(prototype_details).includes(key)) {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>{fieldDescription}</Table.Cell>
                    <Table.Cell>{this.formatKeyValue(key, prototype_details[key])}</Table.Cell>
                  </Table.Row>
                );
              }
              return (
                <Table.Row key={index}>
                  <Table.Cell>{fieldDescription}</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                </Table.Row>
              );
            })}
            {branch_name && <Table.Row key="branch_name">
              <Table.Cell>Branch name</Table.Cell>
              <Table.Cell>{branch_name}</Table.Cell>
            </Table.Row>}
            {commit_id && <Table.Row key="commit_id">
              <Table.Cell>Commit ID</Table.Cell>
              <Table.Cell>{commit_id}</Table.Cell>
            </Table.Row>}
            {ignored_file_paths.length > 0 && <Table.Row key="ignored-file-paths">
              <Table.Cell>Ignored file paths</Table.Cell>
              <Table.Cell>
                  {ignored_file_paths.map(key => {
                    return (<div key={key}>{key}</div>)
                  })}
              </Table.Cell>
            </Table.Row>
            }

          </Table.Body>
        </Table>
      </Segment>
    );
  }

}