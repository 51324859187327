// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Image, Menu, Label, Form } from 'semantic-ui-react';

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleItemClick = async (e, { name, link }) => {
        if (name) {
            let link = '/'
            if (name === 'signout') {
                await this.props.onLogout();
            } else if (name !== 'home') {
                link = link + name;
            }
            this.props.history.push(link);
        }
        if (link) window.open(link)
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    render() {
        const { headerLogoUrl, loginName, userRole } = this.props;
        const isLoggedIn = !!loginName;
        return (
            <Menu fixed='top' inverted>
                <Menu.Item header name="home" onClick={this.handleItemClick}>
                    <Image size='mini' src={headerLogoUrl} style={{ marginRight: '1.5em' }} />
                    Quality Assurance Portal
                </Menu.Item>
                <Menu.Item name='quality_dashboard' disabled={!isLoggedIn} onClick={this.handleItemClick}>Quality Dashboard</Menu.Item>
                <Menu.Item name='quality_stats' disabled={!isLoggedIn} onClick={this.handleItemClick}>Quality Stats</Menu.Item>
                <Menu.Item name='common_quality_findings' disabled={!isLoggedIn} onClick={this.handleItemClick}>Common Quality Findings</Menu.Item>
                <Menu.Item link={this.props.feedback.ASANA_FORM_URL} disabled={!isLoggedIn} onClick={this.handleItemClick}>Feedback</Menu.Item>
                {isLoggedIn &&
                    <Menu.Item name="login" position="right" title={"User role: "+ userRole}>
                        <Label size="small" color='blue'>
                            {loginName}
                        </Label>
                    </Menu.Item>
                }
            </Menu>
        );
    }

}
