// Copyright 2023 Amazon.com, Inc. and its affiliates. All Rights Reserved.
// 
// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at
// 
// http://aws.amazon.com/asl/
// 
// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.
import React from "react";
import { Header, Container, Segment, Message, Icon } from 'semantic-ui-react';

export default function ErrorMessageView(props) {
    const { errorMessage } = this.props;
    return (
      <Segment basic>
        <Container>
          <Header as="h3" color="black">
            <Icon name="window close outline" />
            Oops!
          </Header>
        </Container>
        <Container>
          <Message>
            {errorMessage}
          </Message>
        </Container>
      </Segment>
    )
}