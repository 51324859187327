// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React from 'react';
import { Segment,  Message, } from 'semantic-ui-react';

export default function CreatePrototypePage() {
    return (
        <Segment basic>
        {/* <Image src={QualityHeader} /> */}
        <Message negative>
            Howdy! You cannot create repositories directly. Please contact the 
                &nbsp;<a href="https://demo-factory.corp.amazon.com/app/browse/" target="_blank">Demo Factory</a>
                &nbsp;team to have them create a repository for you!
        </Message>
    </Segment>    );
  }