// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, {Component} from "react";
import { Header, Segment} from 'semantic-ui-react';

export default class Unauthorized extends Component {
    render() {
        return (
            <Segment basic>
                <Header as='p' color='black' textAlign='center'>
                    <p>You do not have access to this portal.</p>
                    <p>For access, please contact the QAP Team @ <a href="mailto:aws-quality-assurance-portal@amazon.com"> aws-quality-assurance-portal@amazon.com</a>.</p>
                </Header>
            </Segment>
        );
    }
}