// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Button, Icon, Segment, Image } from 'semantic-ui-react'
import { colorMap } from './ColorsMap';
import moment from 'moment';
import clickForDetails from "../assets/click-for-details.png";

export class DashboardItem extends Component {

    defaultColor = "black"
    severityBarHeight = 10;

    USER_ROLE_DEVELOPER = "developer";
    USER_ROLE_EXPERT = "expert";

    constructor(props) {
        super(props);
        this.buildStyle = this.buildStyle.bind(this);
        this.dateDiffInDays = this.dateDiffInDays.bind(this);
    }

    buildStyle(severityType, numOfFindings) {
        const width = Math.floor(100 * (numOfFindings / this.props.dashboardItem.totalNumOfFindings))
        const backgroundColor = (severityType in colorMap) ? colorMap[severityType] : this.defaultColor;
        return {
            backgroundColor: backgroundColor,
            width: width + "%",
            minHeight: this.severityBarHeight + "px",
            maxHeight: this.severityBarHeight + "px",
            float: "left"
        }
    }

    buildSeverityBar(self, severityStats) {
        let bar = []
        Object.keys(severityStats).forEach(function (severityType) {
            bar.push(<div
                key={severityType}
                title={severityType + " severity: " + severityStats[severityType] + " findings"}
                style={self.buildStyle(severityType, severityStats[severityType])} />)
        })
        bar.push(<br key="last-severity-element" style={{ clear: "left" }} />)
        return bar
    }

    dateDiffInDays(utcDateString) {
        const now = new Date() // local timezone
        now.setHours(0, 0, 0, 0);
        const analysisDate = new Date(utcDateString.substring(0, 10) + "T00:00:00.000Z") // utc time zone to local time zone
        analysisDate.setHours(0, 0, 0, 0);
        const diffTime = Math.abs(now - analysisDate);  // both local timezone - we're good
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 1) return 'today'
        if (diffDays === 2) return 'yesterday'
        return diffDays + ' days ago'
    }

    formatDateTimeUTCToLocalTimeZone(date) {
        const momentDate = moment(date).local();
        return momentDate.format('MMMM Do YYYY, h:mm:ss a');
    }

    render() {
        const self = this;
        let { severityStats, totalNumOfFindings, result, didRunQualityAnalysis, latestFindingsReview } = this.props.dashboardItem;
        const haveFindingsBeingReviewed = Object.keys(latestFindingsReview).length > 0;
        const buttonStyle = {
            width: "12em",
            height: "6em",
            position: "relative",
        }
        const subHeaderStyle = {
            color: "#232e3f",
            fontSize: "10px"
        }

        const whiteBackgroundStyle = {
            backgroundColor: "rgba(0,0,0,0)"
        }
        const blackBorderStyle = {
            ...buttonStyle,
            boxShadow: "0 0 0 1px #879596"
        }
        const blackHeaderStyle = {
            color: "#232e3f",
            fontWeight: "bold"
        }
        const darkGrayStyle = {
            color: "#888888",
        }
        const darkRedStyle = {
            color: "#CC0000",
        }

        return (
            <Segment basic>
                {didRunQualityAnalysis && totalNumOfFindings > 0 &&
                    <Button
                        basic
                        title="click to view findings details"
                        size='medium'
                        color='red'
                        style={buttonStyle}>
                        <span style={{ fontWeight: "bold" }}>{totalNumOfFindings} Findings</span>
                        <p style={subHeaderStyle}>
                            {this.dateDiffInDays(this.props.dashboardItem.analysisDateTime)}
                        </p>
                        {this.buildSeverityBar(self, severityStats)}
                        {
                            haveFindingsBeingReviewed &&
                            latestFindingsReview['reviewerRole'] === this.USER_ROLE_DEVELOPER &&
                            <div style={{ position: 'absolute', top: '0.2em', left: '0.2em' }}>
                                <Icon title={"Developer review requested by " + latestFindingsReview['reviewerId'] + " on " + this.formatDateTimeUTCToLocalTimeZone(latestFindingsReview['submissionDate'])} style={darkRedStyle} name='bell' />
                            </div>
                        }
                        {
                            haveFindingsBeingReviewed &&
                            latestFindingsReview['reviewerRole'] === this.USER_ROLE_EXPERT &&
                            <div style={{ position: 'absolute', top: '0.2em', left: '0.2em' }}>
                                <Icon title={"Expert feedback provided by " + latestFindingsReview['reviewerId'] + " on " + this.formatDateTimeUTCToLocalTimeZone(latestFindingsReview['submissionDate'])} style={darkGrayStyle} name='bell slash' />
                            </div>
                        }
                        <Image src={clickForDetails} height="16px" align="center" />
                    </Button>
                }
                {didRunQualityAnalysis && totalNumOfFindings === 0 && result === "SUCCESS" &&
                    <Button 
                        basic
                        positive
                        title="Congrats! No findings."
                        size='medium'
                        style={buttonStyle}>
                        <span style={{ color: "#00ad00", fontWeight: "bold" }}>No Findings</span>
                        <p style={subHeaderStyle}>
                            {this.dateDiffInDays(this.props.dashboardItem.analysisDateTime)}
                        </p>
                    </Button>
                }
                {didRunQualityAnalysis && totalNumOfFindings === 0 && result === "FAILED" &&
                    <Button
                        basic 
                        icon
                        title="Error! Quality Check failed."
                        size='medium'
                        labelPosition='left'
                        style={blackBorderStyle}>
                        <Icon color='black' name='x' size='large' style={whiteBackgroundStyle} />
                        <span style={blackHeaderStyle}>Check Failed</span>
                        <p style={subHeaderStyle}>
                            {this.dateDiffInDays(this.props.dashboardItem.analysisDateTime)}
                        </p>
                    </Button>
                }
                {!didRunQualityAnalysis &&
                    <Button
                        basic
                        title="No Runs Yet"
                        size='medium'
                        style={blackBorderStyle}>
                        <span style={blackHeaderStyle}>No Runs Yet</span>
                        <p style={subHeaderStyle}>
                            Push a change to run this quality check
                        </p>
                    </Button>
                }
            </Segment>
        )
    }
}

