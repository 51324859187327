// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

import React, { Component } from 'react';
import { Header, Dropdown, Grid, GridRow, GridColumn, Container, Segment } from 'semantic-ui-react'
import { API } from "aws-amplify";
import { Loader } from "./Loader"
import QualityFindingsChart from "./QualityFindingsChart"
import { colorMap } from './ColorsMap';

export default class PrototypeQualityStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_error: false,
            dashboard_data: []
        }
    }

    componentDidMount() {
        try {
            API.get("PrototypeQualityAPI", "/get_quality_findings_stats")
                .then(response => {
                    console.log(response)

                    this.setState({
                        dashboard_data: response,
                        loading_error: false,
                        loading: false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading_error: true,
                        loading: false
                    })
                    console.error(error)
                });
        }
        catch (error) {
            this.setState({
                loading_error: true,
                loading: false
            })
            console.error(error)
        }
    }

    buildChartDataForRepo(listOfDates, repoData, dimension) {
        let datasets = []
        for (const dimensionKey in repoData[dimension]) {
            datasets.push({
                label: dimensionKey,
                data: repoData[dimension][dimensionKey],
                borderColor: colorMap[dimensionKey],
                backgroundColor: colorMap[dimensionKey],
            })
        }
        return {
            labels: listOfDates,
            datasets: datasets
        }
    }

    generateReposCharts(aggregateKey, listOfDate, reposStats) {
        let charts = []
        let sortedRepos = Object.keys(reposStats).sort()
        for (const repoName of sortedRepos) {
            if (repoName !== aggregateKey) {
                const aggregate_by_severity = this.buildChartDataForRepo(listOfDate, reposStats[repoName], 'by_severity')
                const repoNameVisibilityKey = repoName + '-display-chart';
                const aggregate_by_category = this.buildChartDataForRepo(listOfDate, reposStats[repoName], 'by_category')
                charts.push(
                    <div key={repoName + '-severity-stats-div'}>
                        {repoNameVisibilityKey in this.state && this.state[repoNameVisibilityKey] &&
                            <Grid stackable textAlign='center'>
                                <GridRow columns={1} verticalAlign='middle'>
                                    <Header as="h3">{repoName}</Header>
                                </GridRow>
                                <GridRow columns={2} verticalAlign='middle'>
                                    <GridColumn key={repoName + '-severity-stats-column'}>
                                        <QualityFindingsChart
                                            key={repoName + '-severity-stats'}
                                            title={"Number of Findings grouped by Severity"}
                                            data={aggregate_by_severity} />
                                    </GridColumn>
                                    <GridColumn key={repoName + '-category-stats-colum'}>
                                        <QualityFindingsChart
                                            key={repoName + '-category-stats'}
                                            title={"Number of Findings grouped by Category"}
                                            data={aggregate_by_category} />
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        }
                    </div>
                )
            }
        }
        return charts
    }

    generateReposDropdown(aggregateKey, reposStats) {
        let options = []
        let sortedRepos = Object.keys(reposStats).sort()
        console.log(sortedRepos)
        for (const repoName of sortedRepos) {
            if (repoName !== aggregateKey) {
                options.push({ key: repoName + 'dropdown', text: repoName, value: repoName })
            }
        }
        const onChange = (event, data) => {
            let chartVisibilityState = {}
            for (const repoName in reposStats) {
                if (repoName !== aggregateKey) {
                    const shouldDisplayChart = data.value.includes(repoName);
                    const displayChartElementName = repoName + '-display-chart';
                    chartVisibilityState[displayChartElementName] = shouldDisplayChart
                }
            }
            this.setState(chartVisibilityState)
        }
        return (
            <Dropdown
                placeholder='Choose one or more repositories (they will be displayed at the bottom of the page)' fluid multiple selection
                onChange={onChange}
                options={options} />
        )
    }

    renderDashboard() {

        const { list_of_dates, repos_stats } = this.state.dashboard_data
        const aggregate_key = 'aggregated_results'
        const aggregate_by_severity = this.buildChartDataForRepo(list_of_dates, repos_stats[aggregate_key], 'by_severity')
        const aggregate_by_category = this.buildChartDataForRepo(list_of_dates, repos_stats[aggregate_key], 'by_category')

        return (
            <Container>
                <Header as="h1">Quality Stats</Header>
                <Segment basic>
                    {this.generateReposDropdown(aggregate_key, repos_stats)}
                </Segment>
                <Segment basic>
                    <Grid columns={2} stackable textAlign='center'>
                        <GridRow columns={2} verticalAlign='middle'>
                            <Header as="h3">All Repos (Aggregated)</Header>
                        </GridRow>
                        <GridRow verticalAlign='middle'>
                            <GridColumn>
                                <QualityFindingsChart
                                    title="Total Number of Findings grouped by Severity"
                                    data={aggregate_by_severity} />
                            </GridColumn>
                            <GridColumn>
                                <QualityFindingsChart
                                    title="Total Number of Findings grouped by Category"
                                    data={aggregate_by_category} />
                            </GridColumn>
                        </GridRow>
                    </Grid>
                    {this.generateReposCharts(aggregate_key, list_of_dates, repos_stats)}
                </Segment>
            </Container>
        )
    }

    // Components to explore: Progress (for upload progress), Message (for errors)
    render() {
        return (
            <div>
                {!this.state.loading && this.renderDashboard()}
                {this.state.loading &&
                    <Loader message='"Anything worth doing is worth doing right the first time." ~Unknown' />}
            </div>
        )
    }
}
