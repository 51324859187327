// Copyright 2022 Amazon.com, Inc. and its affiliates. All Rights Reserved.

// Licensed under the Amazon Software License (the "License").
// You may not use this file except in compliance with the License.
// A copy of the License is located at

// http://aws.amazon.com/asl/

// or in the "license" file accompanying this file. This file is distributed
// on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
// express or implied. See the License for the specific language governing
// permissions and limitations under the License.

export const getImageResource = (val) => {
  let imageResource = val;
  if (imageResource.startsWith('../assets')) {
    // Webpack cannot handle requiring dynamic image. We need to construct the parameter with path + suffix
    // See https://github.com/vuejs-templates/webpack/issues/126
    imageResource = require('../assets/' + val + '.png');
  }
  return imageResource;
};

export const mergeClassName = (componentClassNames, passedClassname) => {
  if (!passedClassname) {
    return componentClassNames;
  } else {
    return `${componentClassNames} ${passedClassname}`;
  }
};
